/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `سوق`,
    TRADE: `تجارة`,
    SPOT: `التداول الفوري`,
    OPTIONS: `تداول الخيارات`,
    CONTRACT: `تداول العقود`,
    ASSET: `أصول`,
    COPY_TRADE: `اتبع أوامر`,
    LOAN: `يُقرض`,
    USER_CENTER: `مركز شخصي`,
    SIGNIN: `تسجيل الدخول`,
    SIGNUP: `يسجل`,
    SLOGAN: `EXODUS`
};
