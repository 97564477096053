/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `mercado`,
    TRADE: `comercio`,
    SPOT: `Comercio al contado`,
    OPTIONS: `comercio de opciones`,
    CONTRACT: `comercio por contrato`,
    ASSET: `activos`,
    COPY_TRADE: `Seguir las órdenes`,
    LOAN: `préstamo`,
    USER_CENTER: `Centro personal`,
    SIGNIN: `Acceso`,
    SIGNUP: `registro`,
    SLOGAN: `EXODUS`
};
