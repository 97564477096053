/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `Ana Sayfa`,
    MENU_MARKETS: `Piyasalar`,
    MENU_ASSET: `Varlıklar`,
    MENU_PLAN: `Hizmet`,
    MENU_USER_CENTER: `Kullanıcı Merkezi`,
    LOGOUT: `Çıkış`,
    LOGIN: `Giriş`
};
