/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `Piyasalar`,
    TRADE: `Ticaret`,
    SPOT: `Spot`,
    OPTIONS: `Seçenekler`,
    CONTRACT: `Sözleşme`,
    ASSET: `Varlıklar`,
    COPY_TRADE: `CopyTrade`,
    LOAN: `Kredi`,
    USER_CENTER: `Kullanıcı Merkezi`,
    SIGNIN: `Giriş Yap`,
    SIGNUP: `Kaydol`,
    SLOGAN: `EXODUS`
};
