/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `marché`,
    TRADE: `commerce`,
    SPOT: `Trading au comptant`,
    OPTIONS: `négociation d'options`,
    CONTRACT: `négociation de contrats`,
    ASSET: `actifs`,
    COPY_TRADE: `copie de transactions`,
    LOAN: `Prête`,
    USER_CENTER: `Centre personnel`,
    SIGNIN: `Se connecter`,
    SIGNUP: `registre`,
    SLOGAN: `EXODUS`
};
